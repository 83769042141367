<template>
  <div>
    <div class="form-title"> Setup Agency API Key</div>

    <p class="font-small-3">Go to your Agency View -> Settings -> API keys and copy your agency API key</p>
    <div class="api-key-explanation-video-wrapper">
      <img src="https://i.imgur.com/sURUogf.png" style="width: 100%;object-fit: cover;border-radius:20px;"
           alt="">
    </div>

    <validation-observer ref="setupApiKeyValidaiton">
      <b-form @submit.prevent="setupApiKey">
        <validated-form-input
            label="Agency API Key"
            input-id="agency-api-key-input"
            validation-name="agency api key"
            placeholder="Enter your agency API key"
            v-model="agencyApiKey"
        />

        <b-button type="submit" class="w-100 mt-1 mt-md-2 submit-form-btn" :disabled="loading">
          <template v-if="loading">
            <b-spinner small class="mr-50"/>
            <span class="align-middle">Loading</span>
          </template>
          <span v-else>Continue</span>
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import {BForm, BButton, BSpinner, BEmbed} from "bootstrap-vue";
import AuthCardLeft from "@/layouts/components/auth/AuthCardLeft";
import ValidatedFormInput from "@/layouts/components/shared/ValidatedFormInput";
import AuthService from "@/services/AuthService";
import toastsMixin from "@/mixins/toastsMixin";

export default {
  name: 'SetupApiKey',
  components: {
    ValidationObserver,
    AuthCardLeft,
    BForm,
    BButton,
    BSpinner,
    BEmbed,
    ValidatedFormInput
  },
  mixins: [toastsMixin],
  data() {
    return {
      loading: false,
      agencyApiKey: '',
    }
  },
  methods: {
    async setupApiKey() {
      const isFormValid = await this.$refs.setupApiKeyValidaiton.validate();

      if (this.loading || !isFormValid) return;

      try {
        this.loading = true;
        await AuthService.post.setupApiKeyApiKey(this.agencyApiKey);
        await this.$store.dispatch('auth/authorizeFromLocalJWT');
        this.showSuccessToast('Agency API key successfully saved.');
        this.loading = false;
        return this.$router.push({name: 'auth.setup-locations'});
      } catch (err) {
        this.loading = false;
        if (+err.response.status === 422) {
          return this.showErrorToast('The provided api key is invalid.');
        }
        this.showErrorToast('Something went wrong.');
      }
    }
  }
}
</script>

